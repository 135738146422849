import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '@/views/Login.vue';
import Register from '@/views/Register.vue';
import Home from '@/views/Home.vue';
import RegisterConfirmTicket from '@/views/RegisterConfirmTicket.vue';
import OwnLinks from '@/views/OwnLinks.vue';
import BrowserSaveLink from '@/views/BrowserSaveLink.vue';
import ResetPassword1 from '@/views/ResetPassword1.vue';
import ResetPassword2 from '@/views/ResetPassword2.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
  },
  {
    path: '/register_confirm_ticket',
    name: 'RegisterConfirmTicket',
    component: RegisterConfirmTicket,
  },

  /* Links pages */
  {
    path: '/own_links',
    name: 'OwnLinks',
    component: OwnLinks,
  },

  /* Browser plugin pages */
  {
    path: '/browser_plugin/save_link',
    name: 'BrowserSaveLink',
    component: BrowserSaveLink,
  },

  /* Reset password */
  {
    path: '/reset_password_1',
    name: 'ResetPassword1',
    component: ResetPassword1,
  },
  {
    path: '/reset_password_2',
    name: 'ResetPassword2',
    component: ResetPassword2,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
