<template>
  <div v-if="link">
    <EditLinkModal :link="link" v-if="link.id" modal-title="Update Link" @close="closeWindow"
                   :center="true" />
    <AddLinkModal :link="link" v-else @close="closeWindow" :center="true" />
  </div>
</template>

<script>
import AddLinkModal from '@/components/AddLinkModal.vue';
import EditLinkModal from '@/components/EditLinkModal.vue';

import { myaxios } from '@/lib/myaxios';

export default {
  data: () => ({
    link: null,
  }),
  methods: {
    closeWindow() {
      window.close();
    },
  },
  async created() {
    try {
      const { url } = this.$route.query;
      let { data: link } = await myaxios.get('/api/my_link', {
        params: { url },
      });
      if (!link) {
        let title;
        try {
          ({ data: title } = await myaxios.get('/api/fetch_title', {
            params: { url },
          }));
        } catch (e) {
          title = '';
        }
        link = {
          url,
          title,
          privacy: false,
        };
      }
      this.link = link;
    } catch (error) {
      if (error.status === 401) {
        await this.$router.push(`/login?to=${
          encodeURIComponent(document.location.pathname + document.location.search)
        }`);
      }
    }
  },
  components: { AddLinkModal, EditLinkModal },
};
</script>
