<template>
  <div class="home">
    <p class="about">
      Links is a URL hoarding website, with social bookmarking features.
      <span v-show="!$store.state.user">
        Save your links as private or public.
      </span>
    </p>

    <!-- pagination -->

    <div class="pagination" v-if="page > 1 && links.length > 0">
      <!-- newer -->
      <router-link v-if="newer" :to="{name: 'Home', query: {page: newer}}">newer</router-link>
      <span v-else class="grey">newer</span>
      &nbsp;<span class="grey">|</span>&nbsp;
      <!-- older -->
      <router-link v-if="older" :to="{name: 'Home', query: {page: older}}">older</router-link>
      <span v-else class="grey">older</span>
    </div>

    <!-- links -->

    <div>
      <div class="link" v-for="link in links" :key="link.id">
        <div class="left">
          <div class="would-be-earth"></div>
        </div>
        <div class="main">
          <div class="main-a">
            <a :href="link.url" target="_blank" rel="noopener noreferrer">{{link.title}}</a>
            - {{link.user}}
          </div>
          <div class="url">{{link.url}}</div>
          <div class="desc">{{link.description}}</div>
          <div class="tags" v-if="link.tags">
            Tags: {{link.tags}}
          </div>
        </div>
        <div class="right">
          <div class="date">{{unixToDate(link.epoch)}}</div>
        </div>
      </div>
    </div>

    <!-- pagination -->

    <div class="pagination" v-if="older || newer">
      <!-- newer -->
      <router-link v-if="newer" :to="{name: 'Home', query: {page: newer}}">newer</router-link>
      <span v-else class="grey">newer</span>
      &nbsp;<span class="grey">|</span>&nbsp;
      <!-- older -->
      <router-link v-if="older" :to="{name: 'Home', query: {page: older}}">older</router-link>
      <span v-else class="grey">older</span>
    </div>

  </div>
</template>

<script>
import bus from '@/lib/bus';
import { myaxios } from '@/lib/myaxios';
import dayjs from 'dayjs';

const ROWS_PER_PAGE = 160;

export default {
  name: 'Home',
  data: () => ({
    links: [],
  }),
  computed: {
    page() {
      return parseInt(this.$route.query.page || '1', 10);
    },
    newer() {
      let newer = this.page - 1;
      if (newer < 1) newer = null;
      return newer;
    },
    older() {
      let older = this.page + 1;
      if (this.links.length !== ROWS_PER_PAGE) older = null;
      return older;
    },
  },
  methods: {
    async fetchLinks() {
      const { data: links } = await myaxios.get('/api/all_links', {
        params: { page: this.page },
      });
      this.links = links;
    },
    unixToDate(epoch) {
      return dayjs.unix(epoch).format('ddd, D MMM YYYY');
    },
  },
  async created() {
    bus.$on('fetchLinks', this.fetchLinks);
    await this.fetchLinks();
  },
  beforeDestroy() {
    bus.$off('fetchLinks', this.fetchLinks);
  },
};
</script>

<style lang="scss" scoped>
.link {
  &:not(:first-child) {
    margin-top: 10.5px;
  }
  border: 1px solid grey;

  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.2);

  background-color: #ddd;

  padding: 3px 4px;

  display: flex;

  .left {
    padding-right: 5px;

    img {
      height: 18px;

      &.hidden {
        visibility: hidden;
      }
    }

    .would-be-earth {
      height: 18px;
      width: 18px;
    }
  }

  .main {
    flex-grow: 1;
    flex-shrink: 1;
    overflow-x: hidden;

    .url {
      font-size: x-small;
    }
  }

  .right {
    flex-shrink: 0;

    font-size: smaller;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
  }
}

.about {
  margin-bottom: 2em;
}

.desc {
  margin-top: 8px;
  font-size: small;
}

.tags {
  margin-top: 8px;
  font-size: small;
  color: grey;
}

.pagination {
  margin: 8px 0;
  user-select: none;
  font-size: small;

  .grey {
    color: grey;
  }
}
</style>
