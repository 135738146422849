<template>
<div class="overlay" @click.self="$emit('close')">
  <VueGlobalEvents @keyup.esc="$emit('close')" />
  <div class="modal" :style="{transform: center ? 'translateY(-50%) translateX(-50%)' : false}">
    <h3 style="text-align: center;">
      <span v-if="modalTitle">{{modalTitle}}</span>
      <span v-else>Edit Link</span>
    </h3>
    <form @submit.prevent="submit">
      <table>
        <tr>
          <td><label for="url">URL:</label></td>
          <td>
            <input type="url" v-model.trim="$v.url.$model" tabindex="1"
                   id="url" maxlength="1024" @blur="fetchTitle"/>
          </td>
        </tr>
        <tr>
          <td><label for="title">Title:</label></td>
          <td>
            <input type="text" v-model.trim="$v.title.$model" id="title" maxlength="256"
                   tabindex="-1"/>
          </td>
        </tr>
        <tr>
          <td><label for="description">Description:</label></td>
          <td>
            <textarea v-model="description" id="description" rows="6" tabindex="2">
            </textarea>
          </td>
        </tr>
        <tr>
          <td><label for="tags">Tags:</label></td>
          <td><input type="text" v-model.trim="tags" id="tags" tabindex="3" maxlength="256" /></td>
        </tr>
        <tr>
          <td><label for="privacy">Privacy:</label></td>
          <td>
            <select v-model="privacy" id="privacy" tabindex="4">
              <option :value="true">Private</option>
              <option :value="false">Public</option>
            </select>
          </td>
        </tr>
        <tr style="font-size: smaller;">
          <td>Added:</td>
          <td>{{unixToDate(epoch)}}</td>
        </tr>
        <tr>
          <td colspan="2">
            <button type="submit" :disabled="$v.$invalid" tabindex="5">Submit</button>
          </td>
        </tr>
      </table>
    </form>
  </div>
</div>
</template>

<script>
import bus from '@/lib/bus';
import { required } from 'vuelidate/lib/validators';
import { myaxios } from '@/lib/myaxios';
import dayjs from 'dayjs';
import VueGlobalEvents from 'vue-global-events';

export default {
  name: 'AddLinkModal.vue',
  data: () => ({
    url: null,
    title: null,
    description: null,
    tags: null,
    privacy: true,
    epoch: null,
  }),
  validations: {
    url: { required },
    title: { required },
  },
  props: ['link', 'modalTitle', 'center'],
  methods: {
    unixToDate(epoch) {
      return dayjs.unix(epoch).format('ddd, D MMM YYYY');
    },
    async submit() {
      try {
        await myaxios.put(`/api/links/${this.link.id}`, {
          url: this.url,
          title: this.title,
          description: this.description,
          tags: this.tags,
          privacy: this.privacy,
        });
        bus.$emit('fetchLinks');
        this.$emit('close');
      } catch (error) {
        if (error.status === 409 && error.code === 'duplicate_url') {
          // eslint-disable-next-line no-alert
          alert('You have already submitted this link');
          return;
        }
        // eslint-disable-next-line no-alert
        alert('Couldn\'t submit link for editing - please try again');
      }
    },
    async fetchTitle() {
      const { data: title } = await myaxios.get('/api/fetch_title', {
        params: { url: this.url },
      });
      this.title = title;
    },
  },
  components: { VueGlobalEvents },
  created() {
    this.url = this.link.url;
    this.title = this.link.title;
    this.tags = this.link.tags;
    this.description = this.link.description;
    this.privacy = this.link.privacy;
    this.epoch = this.link.epoch;
  },
};
</script>

<style lang="scss" scoped>
.overlay {
  position: fixed;
  z-index: 1000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.75);
}

.modal {
  background-color: white;
  position: absolute;
  //height: 500px;
  width: 500px;
  top: 50%;
  left: 50%;
  transform: translateY(-73%) translateX(-50%);
  border-radius: 25px;
  padding: 25px;
}

table {
  width: 100%;

  td:first-child {
    text-align: right;
    width: 1px;
  }

  td {
    input, textarea, select {
      width: 100%;
    }

    textarea {
      resize: none;
    }
  }
}
</style>
