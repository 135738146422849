import Vue from 'vue';
import Vuex from 'vuex';
import { myaxios } from '@/lib/myaxios';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: null,
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    removeUser(state) {
      state.user = null;
    },
  },
  actions: {
    async whoami({ commit }) {
      try {
        const { data } = await myaxios.get('/api/whoami');
        commit('setUser', data);
      } catch (e) {
        // eslint-disable-next-line no-alert
        alert('Authentication failed. Please try reloading the page.');
      }
    },
  },
  modules: {
  },
});
