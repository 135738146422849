<template>
  <div>
    <fieldset v-if="! emailHasBeenSent">
      <legend>Register</legend>
      <form @submit.prevent="submit">
        <table class="form">
          <!-- Username -->
          <tr>
            <td><label for="username">Username:</label></td>
            <td>
              <input type="text" v-model.trim="$v.username.$model" id="username" maxlength="20" />
            </td>
          </tr>
          <tr>
            <td />
            <td>
              <div class="error" v-if="$v.username.$dirty && !$v.username.required">
                Username is required
              </div>
              <div class="error" v-else-if="$v.username.$dirty && !$v.username.minLength">
                Username needs to be at least {{$v.username.$params.minLength.min}} characters
              </div>
              <div class="error"
                   v-else-if="$v.username.$dirty && !$v.username.$pending && !$v.username.isUnique"
              >Username is taken</div>
              <div class="error" v-else-if="$v.username.$dirty && !$v.username.pattern">
                Only lowercase letters, numbers and underscore are allowed
              </div>
            </td>
          </tr>
          <!-- Email -->
          <tr>
            <td><label for="email">Email:</label></td>
            <td>
              <input type="email" v-model.trim="$v.email.$model" id="email" maxlength="80" />
            </td>
          </tr>
          <tr>
            <td />
            <td>
              <div class="error" v-if="$v.email.$dirty && !$v.email.required">
                Email is required
              </div>
              <div class="error" v-else-if="$v.email.$dirty && !$v.email.email">
                Invalid email address
              </div>
              <div class="error"
                   v-else-if="$v.email.$dirty && !$v.email.$pending && !$v.email.isUnique">
                An account already exists with this email
              </div>
            </td>
          </tr>
          <!-- Password -->
          <tr>
            <td><label for="password">Password:</label></td>
            <td>
              <input type="password" v-model="$v.password.$model" id="password" maxlength="40" />
            </td>
          </tr>
          <tr>
            <td />
            <td>
              <div class="error" v-if="$v.password.$dirty && !$v.password.required">
                Password is required
              </div>
              <div class="error" v-else-if="$v.password.$dirty && !$v.password.minLength">
                Password needs to be at least {{$v.password.$params.minLength.min}} characters long
              </div>
            </td>
          </tr>
          <!-- Repeat password -->
          <tr>
            <td><label for="repeat_password">Repeat password:</label></td>
            <td>
              <input type="password" v-model="$v.repeatPassword.$model" id="repeat_password"
                maxlength="40"
              />
            </td>
          </tr>
          <tr>
            <td />
            <td>
              <div class="error"
                   v-if="$v.repeatPassword.$dirty && !$v.repeatPassword.sameAsPassword"
              >
                Passwords must be identical
              </div>
            </td>
          </tr>
          <!-- Submit -->
          <tr>
            <td colspan="2" style="text-align: right;">
              <button type="submit" :disabled="$v.$invalid">
                Submit
              </button>
            </td>
          </tr>
        </table>
      </form>
    </fieldset>

    <fieldset v-else>
      <legend>Registration</legend>
      <p>An email to confirm your e-mail address has been sent to you. Please wait for it,
      read it and follow the instructions you find there to complete your registration.</p>
    </fieldset>
    <!-- <pre><code>{{$v}}</code></pre> -->
  </div>
</template>

<script>
import {
  required, email, minLength, sameAs,
} from 'vuelidate/lib/validators';
import { myaxios } from '@/lib/myaxios';

export default {
  data: () => ({
    username: null,
    email: null,
    password: null,
    repeatPassword: null,
    emailHasBeenSent: false,
  }),
  validations: {
    username: {
      required,
      minLength: minLength(3),
      async isUnique(value) {
        const { data } = await myaxios.get('/api/username_email_exists', {
          params: { username: value },
        });

        return !data;
      },
      pattern(value) {
        return /^[a-z0-9_]+$/.test(value);
      },
    },
    email: {
      required,
      email,
      async isUnique(value) {
        const { data } = await myaxios.get('/api/username_email_exists', {
          params: { email: value },
        });

        return !data;
      },
    },
    password: {
      required,
      minLength: minLength(6),
    },
    repeatPassword: {
      sameAsPassword: sameAs('password'),
    },
  },
  methods: {
    async submit() {
      try {
        await myaxios.post('/api/register', {
          username: this.username,
          email: this.email,
          password: this.password,
        });
        this.emailHasBeenSent = true;
      } catch (e) {
        // eslint-disable-next-line no-alert
        alert('System error. Please reload the page and try again.');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  table.form td:first-child {
    text-align: right;
  }
  .error {
    color: red;
    font-size: smaller;
  }
</style>
