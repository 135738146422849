<template>
  <fieldset>
    <legend>Reset password</legend>
    <template v-if="ticketExists === false">
      <p>The link you clicked on has expired. Please try again.</p>
    </template>
    <template v-else-if="ticketExists === true">
      <p>Please enter the new password you wish your account to have:</p>
      <form @submit.prevent="submit">
        <table class="form">
          <tr>
            <td><label for="password">New password:</label></td>
            <td>
              <input type="password" v-model="$v.password.$model" id="password" />
            </td>
          </tr>
          <tr>
            <td />
            <td>
              <div class="error" v-if="$v.password.$dirty && !$v.password.required">
                Password is required
              </div>
              <div class="error" v-else-if="$v.password.$dirty && !$v.password.minLength">
                Password needs to be at least {{$v.password.$params.minLength.min}} characters long
              </div>
            </td>
          </tr>
          <tr>
            <td><label for="repeat_password">Repeat new password:</label></td>
            <td>
              <input type="password" v-model="$v.repeatPassword.$model" id="repeat_password" />
            </td>
          </tr>
          <tr>
            <td />
            <td>
              <div class="error"
                   v-if="$v.repeatPassword.$dirty && !$v.repeatPassword.sameAsPassword"
              >
                Passwords must be identical
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="2" style="text-align: right;">
              <button type="submit" :disabled="$v.$invalid">
                Submit
              </button>
            </td>
          </tr>
        </table>
      </form>
    </template>
    <template v-else>
      <p>Please wait...</p>
    </template>
  </fieldset>
</template>

<script>
import { minLength, required, sameAs } from 'vuelidate/lib/validators';
import { myaxios } from '@/lib/myaxios';

const TYPE = 'reset_password';

export default {
  data: () => ({
    password: '',
    repeatPassword: '',
    ticketExists: null,
    username: '',
  }),
  validations: {
    password: {
      required,
      minLength: minLength(6),
    },
    repeatPassword: {
      sameAsPassword: sameAs('password'),
    },
  },
  computed: {
    code() {
      return this.$route.query.code;
    },
  },
  methods: {
    async submit() {
      try {
        await myaxios.post('/api/reset_password_2', {
          code: this.code,
          password: this.password,
          type: TYPE,
        });
        this.$toasted.show('Password has been changed', {
          theme: 'toasted-primary',
          position: 'top-center',
          duration: 5e3,
        });
        await this.$store.dispatch('whoami');
        await this.$router.push('/');
      } catch (error) {
        if (error.status === 409 && error.code === 'missing_ticket') {
          // eslint-disable-next-line no-alert
          alert('Link has expired. Please try again.');
        } else {
          // eslint-disable-next-line no-alert
          alert('Server error. Please try again.');
        }
      }
    },
  },
  async created() {
    try {
      const { data } = await myaxios.get('/api/ticket_exists', {
        params: {
          code: this.code,
          type: TYPE,
        },
      });
      this.ticketExists = data;
    } catch (e) {
      // eslint-disable-next-line no-alert
      alert('Server error. Please reload this page.');
    }
  },
};
</script>

<style lang="scss" scoped>
table.form td:first-child {
  text-align: right;
}
.error {
  color: red;
  font-size: smaller;
}
</style>
