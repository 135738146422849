<template>
  <fieldset>
    <legend>Reset password</legend>
    <template v-if="!submitted">
      <p>
        Please enter your username or email in the field below,
        to receive instructions on how to reset your password.
      </p>

      <form @submit.prevent="submit">
        Username or email:
        <input type="text" v-model="$v.usernameEmail.$model" />&nbsp;
        <button type="submit" :disabled="$v.$invalid">Submit</button>
      </form>
    </template>
    <template v-else>
      <p>
        Thank you. If a user exists with this identifier, they will receive
        soon receive an email with instructions, valid for three hours from now.
      </p>
    </template>
  </fieldset>
</template>

<script>
import { myaxios } from '@/lib/myaxios';
import { required } from 'vuelidate/lib/validators';

export default {
  data: () => ({
    usernameEmail: '',
    submitted: false,
  }),
  validations: {
    usernameEmail: { required },
  },
  methods: {
    async submit() {
      try {
        await myaxios.post('/api/reset_password_1', {
          usernameEmail: this.usernameEmail,
        });
        this.submitted = true;
      } catch (e) {
        // eslint-disable-next-line no-alert
        alert('Server error. Please try again.');
      }
    },
  },
};
</script>
