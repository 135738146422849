<template>
  <fieldset v-if="success === null">
    <legend>Registration</legend>
    <p>Please wait...</p>
  </fieldset>
  <fieldset v-else-if="success === true">
    <legend>Registration</legend>
    <p>Your user account has been created. You may now use the entire site.</p>
  </fieldset>
  <fieldset v-else>
    <legend>Registration</legend>
    <p>Your user account could not be created. Please try registering again.</p>
  </fieldset>
</template>

<script>
import { myaxios } from '@/lib/myaxios';

export default {
  data: () => ({
    success: null,
  }),
  computed: {
    code() {
      return this.$route.query.code;
    },
    type() {
      return this.$route.query.type;
    },
  },
  async created() {
    try {
      await myaxios.post('/api/confirm_ticket', {
        type: this.type,
        code: this.code,
      });
      this.success = true;
      await this.$store.dispatch('whoami');
    } catch (e) {
      this.success = false;
      // eslint-disable-next-line no-alert
      alert("Couldn't confirm ticket. Please go back and try again.");
    }
  },
};
</script>
