import axios from 'axios';
import Cookies from 'js-cookie';
import MyAxiosError from './MyAxiosError';

/* An axios wrapper that throws simpler errors */
class MyAxios {
  constructor(config = {}) {
    this.axios = axios.create(config);
  }

  get(...args) {
    return this.helper('get', ...args);
  }

  post(...args) {
    return this.helper('post', ...args);
  }

  put(...args) {
    return this.helper('put', ...args);
  }

  delete(...args) {
    return this.helper('delete', ...args);
  }

  async helper(method, ...args) {
    try {
      return await this.axios[method](...args);
    } catch (e) {
      const status = e.response?.status;
      const code = e.response?.data?.code;
      const data = e.response?.data?.data;
      throw new MyAxiosError(status, code, data);
    }
  }
}

// axios instance for internal requests only (adds X-API-Key = 'sid' cookie)
const myaxios = new MyAxios({});
myaxios.axios.interceptors.request.use(
  (config) => {
    const sid = Cookies.get('sid');
    if (sid) {
      // eslint-disable-next-line no-param-reassign
      config.headers = { 'X-API-Key': sid };
    }

    // eslint-disable-next-line implicit-arrow-linebreak
    return config;
  },
  (error) => Promise.reject(error),
);

export { myaxios };

export default 42;
