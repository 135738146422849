<template>
  <div>
    <EditLinkModal @close="editLinkModalOpen = false" v-if="editLinkModalOpen"
                   :link="editedLink" />

    <!-- pagination -->

    <div class="pagination" v-if="page > 1 && links.length > 0">
      <!-- newer -->
      <router-link v-if="newer" :to="{name: 'OwnLinks', query: {page: newer}}">newer</router-link>
      <span v-else class="grey">newer</span>
      &nbsp;<span class="grey">|</span>&nbsp;
      <!-- older -->
      <router-link v-if="older" :to="{name: 'OwnLinks', query: {page: older}}">older</router-link>
      <span v-else class="grey">older</span>
    </div>

    <!-- links -->

    <div>
      <div class="link" v-for="link in links" :key="link.id" style="position: relative;">
        <div class="left">
          <img v-if="!link.privacy" src="@/assets/earth.png" />
          <div v-else class="would-be-earth"></div>
        </div>
        <div class="main">
          <a :href="link.url" target="_blank" rel="noopener noreferrer">{{link.title}}</a>
          <br />
          <div class="url">{{link.url}}</div>
          <div class="desc">
            {{link.description}}
          </div>
          <div class="tags" v-if="link.tags">
            Tags: {{link.tags}}
          </div>
        </div>
        <div class="right">
          <div class="date">{{unixToDate(link.epoch)}}</div>
          <div class="actions">
            <a href="#" @click.prevent="openEditModal(link)">edit</a> /
            <a href="#" @click.prevent="deleteLink(link)">delete</a>
          </div>
        </div>
      </div>
    </div>

    <!-- pagination -->

    <div class="pagination" v-if="older || newer">
      <!-- newer -->
      <router-link v-if="newer" :to="{name: 'OwnLinks', query: {page: newer}}">newer</router-link>
      <span v-else class="grey">newer</span>
      &nbsp;<span class="grey">|</span>&nbsp;
      <!-- older -->
      <router-link v-if="older" :to="{name: 'OwnLinks', query: {page: older}}">older</router-link>
      <span v-else class="grey">older</span>
    </div>
  </div>
</template>

<script>
import bus from '@/lib/bus';
import EditLinkModal from '@/components/EditLinkModal.vue';
import { myaxios } from '@/lib/myaxios';
import dayjs from 'dayjs';

const ROWS_PER_PAGE = 160;

export default {
  name: 'OwnLinks.vue',
  data: () => ({
    links: [],
    editLinkModalOpen: false,
    editedLink: null,
  }),
  computed: {
    page() {
      return parseInt(this.$route.query.page || '1', 10);
    },
    newer() {
      let newer = this.page - 1;
      if (newer < 1) newer = null;
      return newer;
    },
    older() {
      let older = this.page + 1;
      if (this.links.length !== ROWS_PER_PAGE) older = null;
      return older;
    },
  },
  methods: {
    async fetchLinks() {
      try {
        const { data: links } = await myaxios.get('/api/own_links', {
          params: { page: this.page },
        });
        this.links = links;
      } catch (error) {
        if (error.status === 401) {
          await this.$router.push('/login?to=/own_links');
        }
      }
    },
    unixToDate(epoch) {
      return dayjs.unix(epoch).format('ddd, D MMM YYYY');
    },
    async deleteLink(link) {
      // eslint-disable-next-line no-restricted-globals,no-alert
      if (confirm(`Are you sure you want to delete the link "${link.title}"?`)) {
        try {
          await myaxios.delete(`/api/links/${link.id}`);
          await this.fetchLinks();
        } catch (e) {
          // NOTE: this section can be improved: check which line's error this is
          // eslint-disable-next-line no-alert
          alert('Was not able to delete the link. Please try again.');
        }
      }
    },
    openEditModal(link) {
      this.editedLink = link;
      this.editLinkModalOpen = true;
    },
  },
  components: { EditLinkModal },
  async created() {
    bus.$on('fetchLinks', this.fetchLinks);
    await this.fetchLinks();
  },
  beforeDestroy() {
    bus.$off('fetchLinks', this.fetchLinks);
  },
};
</script>

<style lang="scss" scoped>
.url {
  font-size: x-small;
}

.link {
  &:not(:first-child) {
    margin-top: 10.5px;
  }
  border: 1px solid grey;

  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.2);

  background-color: #ddd;

  padding: 3px 4px;

  display: flex;

  .left {
    padding-right: 5px;
    img {
      height: 18px;

      &.hidden {
        visibility: hidden;
      }
    }

    .would-be-earth {
      width: 18px;
      height: 18px;
    }
  }

  .main {
    flex-grow: 1;
    overflow-x: hidden;
  }

  .right {
    flex-shrink: 0;

    font-size: smaller;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
  }
}

.desc {
  margin-top: 8px;
  font-size: small;
}

.tags {
  margin-top: 8px;
  font-size: small;
  color: grey;
}

.pagination {
  margin: 8px 0;
  user-select: none;
  font-size: small;

  .grey {
    color: grey;
  }
}
</style>
