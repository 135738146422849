<template>
  <div>
    <AddLinkModal @close="addLinkModalOpen = false" v-if="addLinkModalOpen" />
    <header class="nav">
      <div class="container">
        <!-- Left nav -->
        <div class="nav-left">
          <div class="logo">
            <router-link to="/">Links</router-link>
          </div>
        </div>

        <!-- Middle nav -->
        <div class="nav-middle" v-if="$store.state.user">
          <router-link to="/">All public links</router-link> |
          <router-link to="/own_links">Your links</router-link> |
          <a href="#" @click.prevent="addLinkModalOpen = true">Add link</a>
        </div>

        <!-- Right nav -->
        <div class="nav-right" v-if="authenticated">
          <template v-if="!$store.state.user">
            <router-link to="/login">login</router-link> |
            <router-link to="/register">register</router-link>
          </template>
          <template v-else>
            <span class="welcome">
              Welcome,
              <span class="username">{{$store.state.user.username}}</span>
            </span> |
            <a href="#" @click.prevent="logout">log out</a>
          </template>
        </div>
      </div>
    </header>

    <main>
      <div class="container">
        <router-view :key="$route.fullPath" />
      </div>
    </main>
  </div>
</template>

<script>
import bus from '@/lib/bus';
import AddLinkModal from '@/components/AddLinkModal.vue';
import { myaxios } from '@/lib/myaxios';

export default {
  data: () => ({
    authenticated: false,
    addLinkModalOpen: false,
  }),
  methods: {
    async logout() {
      await myaxios.post('/api/logout', {});
      await Promise.all(
        this.$store.dispatch('whoami'),
        this.$router.push('/'),
      );
    },
    fetchLinks() {
      bus.$emit('fetchLinks');
    },
  },
  components: {
    AddLinkModal,
  },
  async created() {
    await this.$store.dispatch('whoami');
    this.authenticated = true;
  },
};
</script>

<style lang="scss" scoped>
.container {
  max-width: 1100px;
  padding-left: 20px;
  padding-right: 20px;
  margin: auto;
}

header.nav {
  background-color: #592b00;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid black;
  color: grey;

  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.2);

  a {
    color: white;
  }

  .logo a {
    color: #ffa148;

    &:hover {
      text-decoration: none;
    }
  }

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .welcome {
    color: #ffa148;
  }
}

.logo {
  font-size: xx-large;
}

main {
  padding: 10px 0;
  max-width: 1200px;
  margin: 0 auto;
}

.nav-middle {
  a {
    padding: 3px 5px;
  }

  .router-link-exact-active {
    background-color: #ff8f26;
    color: #261300;
    //font-weight: bold;
    border-radius: 5px;
  }
}
</style>
