<template>
<div>
  <fieldset>
    <legend>Login</legend>
    <form @submit.prevent="submit">
      <table class="form">
        <tr>
          <td><label for="username">Username or email:</label></td>
          <td><input type="text" v-model="$v.username.$model" id="username" maxlength="100" /></td>
        </tr>
        <tr>
          <td><label for="password">Password:</label></td>
          <td>
            <input type="password" v-model="$v.password.$model" id="password" maxlength="40" />
          </td>
        </tr>
        <tr>
          <td colspan="2" style="text-align: right;">
            <button type="submit" :disabled="$v.$invalid">Log in</button>
          </td>
        </tr>
      </table>
    </form>
    <div class="forgot">
      <router-link to="/reset_password_1">
        Forgot your password?
      </router-link>
    </div>
  </fieldset>
</div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { myaxios } from '@/lib/myaxios';

export default {
  data: () => ({
    username: null,
    password: null,
  }),
  validations: {
    username: { required },
    password: { required },
  },
  methods: {
    async submit() {
      try {
        await myaxios.post('/api/login', {
          username: this.username,
          password: this.password,
        });
        await this.$store.dispatch('whoami');
        const { to } = this.$route.query;
        const redirectTo = this.$route.query.redirect_to;
        if (to) {
          await this.$router.push(to);
        } else if (redirectTo) {
          document.location.href = redirectTo;
        } else {
          await this.$router.push('/');
        }
      } catch (error) {
        if (error.status === 409 && error.code === 'invalid_credentials') {
          // eslint-disable-next-line no-alert
          alert('wrong username or password');
          return;
        }
        // eslint-disable-next-line no-alert
        alert('Login failed');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  table.form td:first-child {
    text-align: right;
  }

  .forgot {
    margin-top: 12px;
    font-size: smaller;
  }
</style>
